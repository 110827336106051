@import "assets/css/variables";

.main {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;

  section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 0;
    opacity: 0;
    background: $color_dark_bg;
    transition: transform .6s ease .6s, opacity 0s ease .6s;
  }

  section.active {
    transform: translate3d(0, 0, 0);
    z-index: 1;
    opacity: 1;
    transition: -webkit-transform .6s ease;
    transition: transform .6s ease;
  }

  section#home {
    min-height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  section#home {
    background-image: url('assets/images/large-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media only screen and (max-width: 500px) {
    section#home {
      background-image: url('assets/images/mobile-bg.jpg');
    }
  }

  section#home:after {
    position: absolute;
    content: "";
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
  }
}
