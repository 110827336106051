@import "../../../../assets/css/variables";

.skills {
  .skills-title {
    color: $color_white;
    display: inline;
    line-height: 26px;
    font-size: 24px;
    text-transform: none;
    border-bottom: 2px dashed $color_primary;
  }
  .skills-list{
    margin-top: 15px;
  }
}