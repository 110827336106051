@import "../../assets/css/variables";

.contact-info-list {
  color: $color_text;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  //margin-bottom: 10px;

  .contact-icon-box {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    width: 35px;
    height: 42px;
  }

  .contact-content-box {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
  }

  .contact-icon {
    color: $color_primary_2;
  }
}