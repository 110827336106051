@import "../../assets/css/variables";
.process-bar {
  color: $color_white;
  border-left: 2px solid $color_primary_2;
  padding-left: 20px;
  position: relative;
  .title {
    text-transform: none;
    font-size: 24px;
    line-height: 24px;
  }
  .sub-title {
    font-size: 24px;
    margin-top: 5px;
    line-height: 24px;
    text-transform: none;
    color: $color_primary_2;
  }
  .duration {
    margin-top: 5px;
  }
  .duration-time{
    display: inline-flex;
    line-height: 15px;
  }
  .place{
    display: inline-flex;
    line-height: 15px;
  }
  .content{
    font-size: 16px;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
  }
}

.process-bar::before{
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: $color_bg;
  border: 2px solid $color_primary_2;
}