@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import "../../assets/css/variables";

.logo {
  overflow: hidden;
  padding-bottom: 25px;
  background-color: $color_dark_bg;

  .logo-title {
    margin-top: 20px;
    font-family: 'Dancing Script', 'Poppins', sans-serif;
    font-size: 48px;
    text-transform: none;
    font-weight: 700;
    z-index: 1;
    top: 0;
    left: 50%;
    color: $color_white;
    transform: translateX(-50%);
    display: inline-block;
    background-color: $color_dark_bg;
    position: absolute;
    //position: absolute;
  }

  .color-primary {
    color: $color-primary;
  }

  .logo-title-before {
    width: 50%;
    height: 67px;
    line-height: 67px;
    border-bottom: 2px $color_white solid;
    display: inline-block;
  }

  .logo-title-after {
    width: 50%;
    height: 57px;
    line-height: 57px;
    border-bottom: 2px $color_primary solid;
    vertical-align: top;
    display: inline-block;
  }

  @media only screen and (max-width: 500px) {
    .logo-title {
      margin-top: 10.5px;
      font-size: 32px;
      text-transform: none;
    }
    .logo-title-before {
      height: 42px;
      line-height: 42px;
    }
    .logo-title-after {
      height: 36px;
      line-height: 36px;
    }
  }

}
