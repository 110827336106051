@import "_variables.scss";
.nav-container > div > ul li.active p,
.nav-container > div > ul li:hover p,
.page-title h2 span,
.back-mobile,
.project-info-container .projects-info h3,
.meta p,
.meta i,
.main-text h1 span,
.badges h3 span,
section#blog article h4:hover,
body.dark section#blog article h4:hover,
body.dark.blog article p h4:hover,
.contact-container .contact .rightside h6 i,
.page-title h2 span:nth-child(2),
.skill-title,
.custom-list h6 i,
.blog article h4:hover,
.comments-list .comment-reply,

body.blog.dark .comments-list .comment-reply:hover,
.contact-container ul.social-intro li p,
.navigation li p i,
.resume-items .item .card-header .year i.fa-caret-right,
.badges h3 span,
.chart-bar>.percent {
    color: $color_primary_2;
}
.cd-stretchy-nav.nav-is-visible ul li.active p,
.cd-stretchy-nav.nav-is-visible ul li.active p span,
.cd-stretchy-nav.nav-is-visible ul li p:hover,
.cd-stretchy-nav.nav-is-visible ul li p:hover span {
    color: $color_primary_2 !important;
}
.chart-bar>.item-progress,
.navigation li > div p span:after,
.cd-stretchy-nav ul p::before,
.nav-container > div > ul li:not(:last-child):hover:after,
body.dark .nav-container > div > ul li:not(:last-child):hover:after,
.nav-container > div > ul li.active:not(:last-child):after,
body.dark .nav-container > div > ul li.active:not(:last-child):after,
.contact-container ul.social-intro li p:hover,
.carousel-controls,
body.dark .carousel-controls,
.resume-items .item p,
#progress-line-container,
#header > div.bg,
.ah-headline.clip .ah-words-wrapper::after,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active ,
.btn.btn-secondary:before,
section#blog .post-date,
.resume-items .item-label,
.comments-form button,
.comments-form button:hover,
.comments-form button:focus,
.comments-form button:active,
.back-mobile,
.page-title .title-head-subtitle:before,
.page-title .title-head-subtitle:after{
    background-color: $color_primary;
}

.contact-container ul.social-intro li p,
.btn.btn-secondary,
.btn.btn-secondary:focus,
.chart-bar>.percent {
    border: 1px solid $color_primary;
}

.arrow {
    border: 1px solid $color_primary;
    border-width: 0 1px 1px 0;
}

.resume-items .item:before {
    border-left: 1px solid $color_primary;
}

.resume-items .item .bullet{
    border: 2px solid $color_primary;
}

.personal-info .image-container:before {
    border: 6px solid $color_primary;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    border-color:$color_primary;
}

body.blog .container-fluid.page-title .content-banner {
    border-bottom:8px solid $color_primary;
}
#portfolio-items li p div {
    background: $color_primary;
}