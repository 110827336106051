@import "../../../assets/css/variables";
.contact-form{
  display: inline-grid;
  padding: 25px;
  width: 60%;
  .title{
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    color: $color_title;
    margin-bottom: 35px;
    font-weight: 500;
  }
  .input-width {
    width: 50%;
    display: inline-block;
    padding-right: 20px;
  }
  .text-area-width{
    padding-right: 20px;
  }

  @media only screen and (max-width:940px) {
    .input-width {
      width: 100%;
      padding-right: 0;
    }
    .text-area-width{
      padding-right: 0;
    }
  }
  @media only screen and (max-width:680px) {
    width: 100%;
    padding: 15px 10px;
  }
}