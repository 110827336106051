/* Link Style 3 */
@import "../../assets/css/variables";
.link {
  cursor: pointer;
  display: inline;
  color: $color_white;
  text-decoration: none;
  border-bottom: .125em solid $color_primary;
  box-shadow: inset 0 -0.125em 0 $color_primary;
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

@media (hover: hover) and (pointer: fine) {
  .link:hover {
    box-shadow: inset 0 -1.125em 0 $color_primary;
    //color: #000;
  }
}
//.link:focus {
//  outline: none;
//  background: #ff6407;
//  color: #fff;
//  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
//}

