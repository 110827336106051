@import "fontawsome";
@import "green";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import "animation";


*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #555;
  background: $color_dark_bg;
  font-size: 0.9em;
  font-weight: 400;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  margin: 0;
}

.margin-x-5{
  margin-left: $fs_5;
  margin-right: $fs_5;
}
.cursor-pointer{
  cursor: pointer;
}
.page-animation{
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.animate-page {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.text-center{
  text-align: center;
}
.mt-10{
  margin-top: 10px;
}
.mt-55{
  margin-top: 55px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-50 {
  margin-bottom: 50px;
}

.font-50{
  font-size: 50px;
}
.font-poppins{
  font-family: 'Poppins', sans-serif;
}

.mr-10{
  margin-right: 10px;
}
.mr-25 {
  margin-right: 20px;
}
.mt-15{
 margin-top: 15px;
}
.mt-25{
 margin-top: 25px;
}
.mb-25{
  margin-bottom: 25px;
}
.text-secondary{
  color: $color_secondary;
}
.vertical-align-top{
  vertical-align: top;
}