.portfolio-gallery{
  .portfolio-grid{
    width: 50%;
    display: inline-block;
    padding: 0 10px;
  }
  @media only screen and (max-width:768px) {
    .portfolio-grid{
      width: 100%;
      display: block;
      padding: 0;
      margin-top: 20px;
    }
  }
}