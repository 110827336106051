@import "../../assets/css/variables";
.landing-text-container{
  z-index: 1;
  color: $color_white;
  text-transform: capitalize;
  text-align: center;
  line-height: normal;
  cursor: pointer;
  .title{
    font-size: 18px;
    margin-bottom: 5px;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
  }

  .heading{
    font-size: 42px;
    position: relative;
    font-family: Poppins, 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .name{
    color: $color_primary;
    font-weight: 700;
  }
  .sub-title{
    font-size: 26px;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
    .typed-cursor{
      display: none!important;
    }
  }
  @media only screen and (max-width: 400px) {
    .heading {
      font-size: 34px;
    }
    .sub-title {
      font-size: 20px;
    }
  }
}
.landing-social-container{
  z-index: 1;
  color: #FFFFFF;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  line-height: normal;
  cursor: pointer;
}





