@import "../../assets/css/variables";
.custom-tag {
  background: $color_primary;
  border-radius: 3px 0 0 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.custom-tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.custom-tag::after {
  background: #111111;
  border-bottom: 13px solid transparent;
  border-left: 10px solid $color_primary;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

//.custom-tag:hover {
//  background-color: crimson;
//  color: white;
//}
//
//.custom-tag:hover::after {
//  border-left-color: crimson;
//}