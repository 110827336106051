.navbar{
  p{
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
  }
  .cd-stretchy-nav {
    position: fixed;
    z-index: 2;
    top: 30px;
    left: 30px;
    height: initial;
    line-height: initial;
    width: initial;
    background-color: initial;
    box-shadow: none;
    pointer-events: none;
  }

  .cd-stretchy-nav .stretchy-nav-bg {
    /* this is the stretching navigation background */
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background: #222;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    transition: 0.2s, box-shadow 0.2s;
  }

  .cd-stretchy-nav.nav-is-visible {
    pointer-events: auto;
  }

  .cd-stretchy-nav.nav-is-visible .stretchy-nav-bg {
    height: 100%;
    width: 110%;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
  }

  .cd-nav-trigger {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    pointer-events: auto;
  }

  .cd-nav-trigger span, .cd-nav-trigger span::after, .cd-nav-trigger span::before {
    /* this is the hamburger icon */
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #ffffff;
  }

  .cd-nav-trigger span {
    /* middle line of the hamburger icon */
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    transition: background-color 0.2s;
  }

  .cd-nav-trigger span::after, .cd-nav-trigger span::before {
    /* top and bottom lines of the hamburger icon */
    content: '';
    top: 0;
    left: 0;
    backface-visibility: hidden;
    transition: transform 0.2s;
  }

  .cd-nav-trigger span::before {
    transform: translateY(-6px);
  }

  .cd-nav-trigger span::after {
    transform: translateY(6px);
  }

  .nav-is-visible .cd-nav-trigger span {
    background-color: transparent;
  }

  .nav-is-visible .cd-nav-trigger span::before {
    transform: rotate(-45deg);
  }

  .nav-is-visible .cd-nav-trigger span::after {
    transform: rotate(45deg);
  }

  .cd-stretchy-nav ul {
    position: relative;
    z-index: 2;
    padding: 53px 0 22px;
    visibility: hidden;
    transition: visibility 0.3s;
    text-align: left;
  }

  .cd-stretchy-nav ul li {
    float: none;
    transition: initial;
  }

  .cd-stretchy-nav ul p {
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 1em 0 calc(1em + 60px);
    color: rgba(255, 255, 255, .9);
    text-transform: uppercase;
    font-size: 16px;
    transition: color 0.2s;
  }

  .cd-stretchy-nav ul p span {
    color: #FFFFFF;
  }


  .cd-stretchy-nav ul p::after {
    /* navigation item icons */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 22px;
    top: 0;
    text-align: center;
    opacity: 0;
    font-family: "FontAwesome";
  }

  .cd-stretchy-nav ul p::before {
    /* line visible next to the active navigation item */
    content: '';
    position: absolute;
    width: 3px;
    height: 16px;
    top: 50%;
    left: 60px;
    transform: translateX(3px) translateY(-50%) scaleY(0);
  }

  .cd-stretchy-nav ul li:first-of-type p::after {
    /* change custom icon using Font Awesome Icons */
    content: "\f015";
  }

  .cd-stretchy-nav ul li:nth-of-type(2) p::after {
    content: "\f007";
  }

  .cd-stretchy-nav ul li:nth-of-type(3) p::after {
    content: "\f0b1";
  }

  .cd-stretchy-nav ul li:nth-of-type(4) p::after {
    content: "\f1fc";
  }

  .cd-stretchy-nav ul li:nth-of-type(5) p::after {
    content: "\f0e6";
  }

  .cd-stretchy-nav ul span {
    /* navigation item labels */
    display: block;
    opacity: 0;
    transform: translateX(-25px);
  }

  .cd-stretchy-nav.nav-is-visible ul {
    visibility: visible;
  }

  .cd-stretchy-nav.nav-is-visible ul p::after {
    /* navigation item icons */
    animation: scaleIn 0.15s backwards;
    opacity: 1;
  }

  .cd-stretchy-nav.nav-is-visible ul li.active p {
    color: #ffffff;
  }

  .cd-stretchy-nav.nav-is-visible ul li.active p::after {
    opacity: 1;
  }

  .cd-stretchy-nav.nav-is-visible ul li.active p::before {
    transform: translateX(-3px) translateY(-50%) scaleY(1);
    transition: transform 0.15s 0.3s;
  }

  .cd-stretchy-nav.nav-is-visible ul span {
    opacity: 1;
    transform: translateX(0);
    animation: slideIn 0.15s backwards;
    transition: transform 0.2s;
  }

  .cd-stretchy-nav.nav-is-visible.lighter ul span {
    text-shadow: none;
  }

  .cd-stretchy-nav.nav-is-visible ul p:hover,
  .cd-stretchy-nav.nav-is-visible.lighter ul p:hover {
    color: #ffffff;
    background-color: initial;
    opacity: 1;
  }

  .cd-stretchy-nav.nav-is-visible.lighter ul p:hover {
    color: #999;
  }

  .cd-stretchy-nav.nav-is-visible ul p:hover:after,
  .cd-stretchy-nav.nav-is-visible.lighter ul p:hover:after {
    opacity: 1;
  }

  .cd-stretchy-nav.nav-is-visible ul p:hover span {
    transform: translateX(5px);
  }

  .cd-stretchy-nav.nav-is-visible ul li:first-of-type p::after,
  .cd-stretchy-nav.nav-is-visible ul li:first-of-type span {
    animation-delay: 0.05s;
  }

  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(2) p::after,
  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(2) span {
    animation-delay: 0.1s;
  }

  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(3) p::after,
  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(3) span {
    animation-delay: 0.15s;
  }

  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(4) p::after,
  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(4) span {
    animation-delay: 0.2s;
  }

  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(5) p::after,
  .cd-stretchy-nav.nav-is-visible ul li:nth-of-type(5) span {
    animation-delay: 0.25s;
  }

  @media only screen and (max-width: 500px) {
    .cd-stretchy-nav{
      top: 15px;
      left: 15px;
    }
    .cd-nav-trigger{
      height: 45px;
      width: 45px;
    }
    .cd-stretchy-nav .stretchy-nav-bg{
      height: 45px;
      width: 45px;
    }
}

  /* --------------------------------

  keyframes

  -------------------------------- */

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 0.6;
      transform:scale(1);
    }
  }
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-25px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }


}