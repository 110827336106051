.input-box{
  .input-field{
    width: 100%;
    height: 38px;
    font-size: 16px;
    background-color: transparent;
    color: #fff;
    margin-bottom: 30px;
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #888;
    transition: .2s;
  }
  .input-field:focus{
    border-radius: 4px;
  }
  .input-error{
    border: 1px solid #da321e  !important;
  }
  .input-error::placeholder{
    color: #da321e;
  }
  .text-area{
    height: 135px;
  }
}