@import "../../assets/css/variables";
.content-title {
  color: $color_white;
  margin-bottom: 20px;
  h2 {
    display: inline;
    font-size: 30px;
    text-transform: none;
    border-bottom: 2px solid $color_primary_2;
  }
}