@import "../../assets/css/variables";
.card{
  border-radius: 8px;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  background-color: $color_bg;
  img{
    cursor: pointer;
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  @media only screen and (max-width:768px) {
    img{
      height: 300px;
    }
  }
  .card-heading{
    margin: 0 10px 0;
    cursor: default;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: $color_white;
  }
  .card-sub-heading{
    font-size: 14px;
    cursor: default;
    margin: 0 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: $color_text;
  }
  .card-external-link{
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin: 5px 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: $color_primary_2;
    line-height: 11px;
    .text{
      display: table-cell;
      vertical-align: top;
    }
    .icon{
      display: table-cell;
      i{
        margin-left: 5px;
      }
    }
  }
  .card-text{
    margin-bottom: 10px;
  }
}