@import "../../assets/css/variables";
.btn {
  box-shadow: none !important;
  background-color: $color_primary;
  border-radius: 8px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  text-transform: uppercase;
  color: $color_white;
  padding: 0 33px;
  outline: none !important;
  overflow: hidden;
  display: inline-block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  letter-spacing: .5px;
  border: 0;
}

.btn span {
  position: relative;
  z-index: 2;
}

.btn:before {
  position: absolute;
  content: "";
  top: 0;
  height: 102%;
  left: -25%;
  width: 0;
  background-color: $color_secondary;
  transform: skew(-22deg);
  transition-duration: .6s;
  z-index: 1;
}



.btn i {
  padding-right: 11px;
}


.btn.btn-secondary {
  color: $color_primary;
}

.btn.btn-secondary,
.btn.btn-secondary:after {
  background: transparent;
}

.btn.btn-secondary:hover {
  color: #fff;
}



@media (hover: hover) and (pointer: fine) {
  .btn.btn-secondary:before {
    background: $color_secondary;
  }
  .btn:hover:before {
    width: 180%;
  }
  .btn:hover{
    background-color: transparent;
    transition-duration: .6s;
  }
  .btn:hover {
    box-shadow: none;
    color: $color_white;
  }
}



