@import "../../assets/css/variables";
.container{
  width: 90%;
  background-color: $color_bg ;
  padding: 15px;
  border-radius: 8px;
  margin: 30px auto;
}
@media only screen and (max-width: 500px) {
  .container{
    width: 92%;
  }
}