$color_white: #fff;
$color_primary_2: #2997FF;
$color_primary: #6857EA;
//$color_primary:  #7A6BEF;
$color_secondary: #222;
$color_dark_bg: #030303;
$color_bg:#111111;
$color_title: #ffff;
$color_text: #ddd;
$fs_5: 5px;
